.issuers {
  .product {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0 0.28571rem rgba(60, 60, 60, .2);
    background-color: #fff;
    padding: 2.14286rem 2.5rem;
    margin-bottom: 1.85714rem;
    .prod_text {
      margin-right: 20px;
      width: 100%;
      a {
        text-decoration: none;
      }
    }
    .prod_img {
      width: 20%;
      img {
        width: 100%;
      }
    }
  }

  .issuer {
    iframe {
      width: 100%;
      height: 100%;
    }
    .issuer_index {
      width: 100%;
      height: 40px;
    }

    .tabset {
      > input[type="radio"] {
        position: absolute;
        left: -200vw;
      }
      .tab-panel {
        display: none;
      }
      > input {
        &:first-child:checked ~ .tab-panels > .tab-panel:first-child,
        &:nth-child(3):checked ~ .tab-panels > .tab-panel:nth-child(2),
        &:nth-child(5):checked ~ .tab-panels > .tab-panel:nth-child(3),
        &:nth-child(7):checked ~ .tab-panels > .tab-panel:nth-child(4),
        &:nth-child(9):checked ~ .tab-panels > .tab-panel:nth-child(5),
        &:nth-child(11):checked ~ .tab-panels > .tab-panel:nth-child(6) {
          display: block;
        }

        &:checked + label {
          border-color: #ccc;
          border-bottom: 1px solid #fff;
          margin-bottom: -1px;
          color: #06c;
        }
      }

      > label {
        position: relative;
        display: inline-block;
        padding: 8px 16px;
        border: 1px solid transparent;
        border-bottom: 0;
        cursor: pointer;
        font-weight: 600;
        &:hover,
        &:focus + label {
          color: #06c;
        }
      }
    }
    .tab-panel {
      padding: 24px 0;
      border-top: 1px solid #ccc;
    }

    #overview {
      .chart {
        width: 100%;
        height: 100vh;
        iframe {
          width: 100%;
          height: 100%;
        }
      }
      .info {
        padding: 8px;
        h4 {
          margin-top: 8px;
        }
        .elem {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row;
          padding-bottom: 4px;
          &:hover {
            background: rgba(211, 211, 211, 0.5);
          }
          .elem_title {
            padding-right: 4px;

          }
          .value {
            font-weight: bold;

          }
        }
      }
      .issuers_section_title {
        margin-bottom: 16px;
        padding-top: 4px;
        border-top: 1px solid lightgrey;
      }
      .latest_news {
        margin-top: 24px;
        .news_iframe {
          width: 100%;
        }
        .news_item {
          margin-bottom: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .news_title {
            a {
              text-decoration: none;
            }
            .news_date {
              color: grey;
            }
          }
        }
      }
      .financial_calendar {
        margin-top: 24px;
        .events {
          border: 1px solid lightgrey;
          border-bottom: none;
          .event {
            text-decoration: none;
            display: flex;
            align-items: center;
            border-bottom: 1px solid lightgrey;
            padding: 4px;
            .event_date {
              width: 40%;
              min-width: 40%;
              text-align: right;
              padding-right: 24px;
            }
            .event_title {
              width: 60%;
              min-width: 60%;
              text-align: left;
            }
            &:hover {
              background: lightgrey;
            }
          }
        }
      }
      .shareholder_structure {
        margin-top: 24px;
        .shareholder_list,
        .shareholder_total {
          border: 1px solid lightgrey;
          border-bottom: none;
          display: flex;
          align-items: center;
          padding: 4px 8px;
          &.header {
            font-weight: bold;
            background: lightgrey;
          }
          .shareholder {
            width: 60%;
            min-width: 60%;
          }
          .shares {
            width: 20%;
            min-width: 20%;
          }
          .percent {
            width: 20%;
            min-width: 20%;
            text-align: right;
          }
        }
        .shareholder_total {
          font-weight: bold;
          border: 1px solid lightgrey;
          background: lightgrey;
        }

      }
      .dividends {
        margin-top: 24px;
        .dividends_list {
          .dividend_header,
          .dividend_item {
            display: flex;
            align-items: center;
            border: 1px solid lightgrey;
            border-bottom: 0;
            padding: 4px 8px;
            &:last-child {
              border-bottom: 1px solid lightgrey;
            }
            div {
              //width: calc(100%/5);
              min-width: calc(100%/5);
              padding-right: 4px;
              >div {
                display: none;
              }
              &:last-child {
                text-align: right;
                padding-right: 0;
              }
              a {
                text-decoration: none;
              }
            }
          }
          .dividend_header {
            font-weight: bold;
            font-size: 0.8rem;
            background: lightgrey;
          }
        }
      }
      .coupons {
        margin-top: 24px;
        .coupons_list {
          .coupons_header,
          .coupons_item {
            display: flex;
            align-items: center;
            border: 1px solid lightgrey;
            border-bottom: 0;
            padding: 4px 8px;
            &:last-child {
              border-bottom: 1px solid lightgrey;
            }
            div {
              //width: calc(100%/4);
              min-width: calc(100%/4);
              padding-right: 4px;
              >div {
                display: none;
              }
              &:last-child {
                text-align: right;
                padding-right: 0;
              }
              a {
                text-decoration: none;
              }
            }
          }
          .coupons_header {
            font-weight: bold;
            font-size: 0.8rem;
            background: lightgrey;
          }
        }
      }
    }

    #trading {
      .ticket {
        .ticket_box {
          margin-top: 24px;
          background: rgba(211, 211, 211, 0.5);
          .ticket_row {
            display: flex;
            align-items: center;
            padding: 8px 16px;
            .key {
              width: 50%;
              min-width: 50%;
              font-weight: bold;
            }
            .value {
              width: 50%;
              min-width: 50%;
            }
          }
        }

      }

    }

    #news {
      .news_item {
        margin-bottom: 8px;
        padding-bottom: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid lightgrey;
        .news_title {
          a {
            text-decoration: none;
          }
          .news_date {
            color: grey;
          }
        }
      }
    }

    #profile {
      .partner {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        text-decoration: none;
        img {
          display: block;
          width: 100%;
          padding: 0 16px;
        }
        .partner_title{
          display: block;
          border-top: 1px solid #e9e9e9;
          width: 100%;
          text-align: center;
          padding: 16px;
          font-weight: bold;
          color: black;
        }
        &:last-child{
          margin-bottom: 1.85714rem;
        }
        .partner__content {
          padding: 8px 0 8px 2.5rem
        }

        &:hover {
          .partner_title{
            background-color: #f9f9f9;
          }
        }
      }

    }

    #other {

    }
  }
}

@media screen and (max-width: 1440px) { // max-width: 1440px
}

@media screen and (max-width: 1024px) { // max-width: 1024px
}

@media screen and (max-width: 768px) { // max-width: 768px
  .issuers .issuer #overview .dividends .dividends_list {
    .dividend_header {
      display: none;
    }
    .dividend_item {
      font-size: 80%;
      flex-wrap: wrap;
      div {
        padding-bottom: 4px;
        &:last-child {
          text-align: left;
          padding-bottom: 4px;
        }
        >div {
          text-align: left;
          display: block;
          font-weight: bold;
        }
      }
    }
  }
  .issuers .issuer #overview .coupons .coupons_list {
    .coupons_header {
      display: none;
    }
    .coupons_item {
      font-size: 80%;
      flex-wrap: wrap;
      div {
        padding-bottom: 4px;
        &:last-child {
          text-align: left;
          padding-bottom: 4px;
        }
        >div {
          text-align: left;
          display: block;
          font-weight: bold;
        }
      }
    }
  }
}

@media screen and (max-width: 590px) { // max-width: 590px
}

@media screen and (max-width: 320px) { // max-width: 320px
}