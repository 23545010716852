.rules-regulations {
  .tertiary__title {
    .download_box {
      float: right;
    }
  }
  .rules_list {
    .document {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: .07143rem solid #e1e1e1;
      padding-bottom: 20px;
      margin-bottom: 20px;
      &:last-child {
        border: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    .description {
      max-width: 90%;
        .title {
          a {
            text-decoration: none;
          }
        }
        .exc {

        }
      }
      .download_box {
        width: 10%;
        text-align: right;
        .download {
          text-decoration: none;
          .icon__pdf {

          }
        }
      }
    }
  }
}

.go_top {
  display: block;
  position: fixed;
  text-decoration: none;
  z-index: 1;
  bottom: 50px;
  right: 50px;
  &:before {
    display: block;
    content: '';
    width: 40px;
    height: 40px;
    border: 10px solid #c09a5d;
    border-bottom-color: transparent;
    border-right-color: transparent;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  span {
    position: relative;
    top: -16px;
    left: -8px;
  }
}

@media screen and (max-width: 1440px) { // max-width: 1440px
}

@media screen and (max-width: 1024px) { // max-width: 1024px
}

@media screen and (max-width: 768px) { // max-width: 768px
}

@media screen and (max-width: 590px) { // max-width: 590px
}

@media screen and (max-width: 320px) { // max-width: 320px
}