.market_update {
  .sidebar_calendar {
    margin-top: 32px;
    caption {
      padding: 16px;
      font-weight: bold;
    }
    table {
      margin: 0;
    }
    a {
      padding: 0;
      border: none;
      font-weight: bold;
    }
  }

}

@media screen and (max-width: 1440px) { // max-width: 1440px
}

@media screen and (max-width: 1024px) { // max-width: 1024px
}

@media screen and (max-width: 768px) { // max-width: 768px
}

@media screen and (max-width: 590px) { // max-width: 590px
}

@media screen and (max-width: 320px) { // max-width: 320px
}