.aix-members {
  .partners {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    .partner {
      width: 30%;
      min-width: 30%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      text-decoration: none;
      img {
        display: block;
        width: 100%;
        padding: 16px;
        margin: auto;
        min-height: 100px;
      }
      .partner_title{
        display: block;
        border-top: 1px solid #e9e9e9;
        width: 100%;
        text-align: center;
        padding: 16px;
        font-weight: bold;
        color: black;
      }
      &:last-child{
        margin-bottom: 1.85714rem;
      }

      &:hover {
        .partner_title{
          background-color: #f9f9f9;
        }
      }
    }
  }
}

.aix-member{

}

@media screen and (max-width: 1440px) { // max-width: 1440px
}

@media screen and (max-width: 1024px) { // max-width: 1024px
}

@media screen and (max-width: 768px) { // max-width: 768px
}

@media screen and (max-width: 590px) { // max-width: 590px
}

@media screen and (max-width: 320px) { // max-width: 320px
}