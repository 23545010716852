.official_list {
  .tertiary__title {
    .download_box {
      float: right;
    }
  }
  .l_filter {
    position: absolute;
    z-index: 1;
    text-align: right;
    right: 2.85714rem;
    top: 0;
    background: white;
    .l_header{
      color: initial;
      position: relative;
      padding: 10px 27px 4px 16px;
      &:after{
        position: absolute;
        top: 10px;
        right: 8px;
        content: '';
        width: 5px;
        height: 5px;
        border: 5px solid #d3d3d3;
        border-top-color: transparent;
        border-left-color: transparent;
        transform: rotate(45deg);
      }
    }
    .l_categories{
      display: none;
    }
    &:hover{
      .l_categories{
        display: block;
        border: 1px solid lightgrey;
        border-top: none;
      }
    }
    .l_cate{
      display: block;
      text-decoration: none;
      color: initial;
      padding: 4px 16px;
      &:hover{
        color: blue;
      }
    }
  }
  .list {
    .list_header,
    .list_item {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding: 16px 0;
      * {
        overflow: hidden;
      }
      div {
        padding-right: 8px;
        span {
          display: none;
        }
      }
      .listing-date {
        width: 100px;
      }
      .ticker {
        width: 100px;
      }
      .company {
        width: 120px;
      }
      .asset_type {
        width: 100px;
      }
      .admission_type {
        width: 100px;
      }
      .segment {
        width: 100px;
      }
      .admission_notice {
        width: 100px;
      }
      a {
        text-decoration: none;
      }
    }
    .list_header {
      text-transform: capitalize;
      font-weight: bold;
      a {
        font-weight: bold;
      }
      .up,
      .down {
        display: inline-block;
        width: 8px;
        height: 8px;
        border: 4px solid #c09a5d;
        border-bottom-color: transparent;
        border-right-color: transparent;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      .down {
        -webkit-transform: rotate(225deg);
        transform: rotate(225deg);
      }
    }
    .list_item {
      border-top: 1px solid lightgrey;
    }
  }

}

@media screen and (max-width: 1440px) { // max-width: 1440px
}

@media screen and (max-width: 1024px) { // max-width: 1024px
  .official_list {
    .tertiary__content {
      padding: 20px;
    }
    .list {
      .list_header,
      .list_item {
        font-size: 90%;
        .company {
          width: 110px;
        }
        .listing-date,
        .ticker,
        .asset_type,
        .admission_type,
        .segment,
        .admission_notice {
          width: 80px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) { // max-width: 768px
  .official_list {
    .list {
      .list_header {
        display: none;
        align-items: unset;
        justify-content: unset;
      }
      .list_item {
        align-items: unset;
        justify-content: unset;
        >div {
          min-width: 33%;
          max-width: 33%;
        }
        div {
          margin-bottom: 8px;
          span {
            display: block;
            font-weight: bold;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 590px) { // max-width: 590px
}

@media screen and (max-width: 320px) { // max-width: 320px
}