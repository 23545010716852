.videos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  .video {
    display: block;
    width: 255px;
    min-width: 255px;
    height: 190px;
    overflow: hidden;
    position: relative;
    margin-bottom: 24px;
    .gallery__caption {
      position: absolute;
      width: 100%;
      bottom: 0;
    }
  }
}

@media screen and (max-width: 1440px) { // max-width: 1440px
}

@media screen and (max-width: 1024px) { // max-width: 1024px
}

@media screen and (max-width: 768px) { // max-width: 768px
  .videos {
    .video {
      width: 100%;
    }
  }
}

@media screen and (max-width: 590px) { // max-width: 590px
}

@media screen and (max-width: 320px) { // max-width: 320px
}