.vacancy {
  .news__post {
    padding: 1.28571rem;
    .news__post-date {
      width: 15%;
      margin: 0;
    }
    .preview_block {
      width: 85%;
      padding-left: 3.42857rem;
      a {
        text-decoration: none;
      }
      .exc {
        margin: 0;
        padding-right: 120px;
      }
      .read-more {
        display: block;
        position: absolute;
        right: 1.28571rem;
        bottom: 1.28571rem;
        text-align: center;
        appearance: none;
        background-color: #c09a5d;
        color: #fff;
        border: 0;
        border-radius: 1.25rem 0;
        line-height: 2.5rem;
        width: 6.78571rem;
        transition: .3s ease;
        &:hover {
          background-color: #002f6c;
          border-radius: 0 1.25rem;
        }
      }
    }
  }

  .pagination {
    text-align: right;
    .pagination__wrapper {
      float: none;
    }
  }

  .hidden {
    display: none;
  }

  .contact {
    margin: 0 -.5rem;
  }

  .textarea-field,
  .upload-field,
  h4,
  .submit {
    padding: 0 .5rem;
  }
  .textarea-field {
    float: left;
    width: 100%;
  }

  .apply-button {
    display: block;
    margin: 40px auto;
    text-align: center;
    appearance: none;
    background-color: #c09a5d;
    color: #fff;
    border: 0;
    border-radius: 1.25rem 0;
    line-height: 2.5rem;
    width: 6.78571rem;
    transition: .3s ease;
    &.submit {
      margin: 0 .5rem;
    }
    &:hover {
      background-color: #002f6c;
      border-radius: 0 1.25rem;
    }
  }

  .vacancies_general_form {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    margin-top: 40px;
    .form_upload {
      width: 50%;
      min-width: 50%;
      label,
      .submit {
        margin: 0;
        padding: 0;
      }
    }
    .hr_contacts {
      width: 35%;
      min-width: 35%;
    }
  }
}

@media screen and (max-width: 1440px) { // max-width: 1440px
}

@media screen and (max-width: 1024px) { // max-width: 1024px
}

@media screen and (max-width: 768px) { // max-width: 768px
}

@media screen and (max-width: 590px) { // max-width: 590px
  .vacancy {
    .news__post {
      .news__post-date {
        width: 100%;
      }
      .preview_block {
        width: 100%;
        padding: 0;
      }
    }

  }
}

@media screen and (max-width: 320px) { // max-width: 320px
}