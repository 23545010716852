.education_chapters {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 40px auto;
  .chapter {
    display: block;
    width: 40%;
    min-width: 40%;
    height: 190px;
    overflow: hidden;
    position: relative;
    margin-bottom: 24px;
    .caption {
      position: absolute;
      width: 100%;
      bottom: 0;
      font-weight: 400;
      background-color: #002f6c;
      color: #fff;
      padding: .57143rem 1.42857rem;
      margin: 0;
    }
  }
}

@media screen and (max-width: 1440px) { // max-width: 1440px
}

@media screen and (max-width: 1024px) { // max-width: 1024px
}

@media screen and (max-width: 768px) { // max-width: 768px
}

@media screen and (max-width: 590px) { // max-width: 590px
}

@media screen and (max-width: 320px) { // max-width: 320px
}