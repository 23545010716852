.products {
  .product {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0 0.28571rem rgba(60, 60, 60, .2);
    background-color: #fff;
    padding: 2.14286rem 2.5rem;
    margin-bottom: 1.85714rem;
    .prod_text {
      margin-right: 20px;
      width: 100%;
      a {
        text-decoration: none;
      }
    }
    .prod_img {
      width: 20%;
      img {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 1440px) { // max-width: 1440px
}

@media screen and (max-width: 1024px) { // max-width: 1024px
}

@media screen and (max-width: 768px) { // max-width: 768px
}

@media screen and (max-width: 590px) { // max-width: 590px
}

@media screen and (max-width: 320px) { // max-width: 320px
}