a.news__category {
  transition: all 0.2s;
  &::before {
    transition: all 0.2s;
  }
  &:hover {
    background: #002f6c;
    color: white;
    &::before {
      background: #002f6c;
    }
  }
}

@media screen and (max-width: 1440px) { // max-width: 1440px
}

@media screen and (max-width: 1024px) { // max-width: 1024px
}

@media screen and (max-width: 768px) { // max-width: 768px
}

@media screen and (max-width: 590px) { // max-width: 590px
  .tertiary__title{
    display: block;
    padding-top: 0;
  }
}

@media screen and (max-width: 320px) { // max-width: 320px
}