.hidden {
  display: none;
}

.hero {
  //min-height: 15.85714rem;
  //height: auto;
  .hero__wrapper {
    padding: 5.71429rem 3.57143rem;
  }
}

.sidebar li.current-menu-item {
  box-shadow: inset 0 0.35714rem 0.35714rem rgba(0, 0, 0, .05);
  background-color: #e8e8e9;
}

.companies__list {
  .icon {
    right: -20px;
  }
  li {
    padding-right: 20px;
  }
}

.widget_newsletterwidget {
  form {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    width: 100%;
    justify-content: flex-end;
    .tnp-privacy-field {
      order: 1;
      color: #fff;
      width: 50rem;
      padding-left: 32px;
      input {
        display: none;
      }
      label {
        margin: 0;
        cursor: pointer;
        &:before {
          content: '';
          display: block;
          width: 1.42857rem;
          height: 1.42857rem;
          cursor: pointer;
          position: absolute;
          top: 33%;
          left: 0;
          background: #fff;
        }
        &:after {
          content: '';
          width: .85714rem;
          height: .42857rem;
          position: absolute;
          top: 55%;
          left: 10px;
          border: .14286rem solid #213564;
          border-top: 0;
          border-right: 0;
          background: 0 0;
          opacity: 0;
          -webkit-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg);
          margin-top: -.14286rem;
        }
        &:hover {
          &:after {
            opacity: 0.5;
          }
        }
        &.checked {
          &:after {
            opacity: 1;
          }
        }
      }
    }
    .tnp-field-email {
      order: 2;
      position: relative;
      top: -10px;
      label {
        display: none;
      }
      .tnp-email {
        height: 3.57143rem;
        line-height: 1.85714rem;
        width: 38.57143rem;
        border-color: #fff;
        border-radius: 0;
        display: block;
        padding: .5rem .75rem;
        border: 0;
      }
    }
    .tnp-field-button {
      order: 3;
      position: relative;
      top: -10px;
      .tnp-submit {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 11.42857rem;
        border: 0;
        border-left: .71429rem solid #c09a5d;
        background-color: #002556;
        color: #fff;
        -webkit-transition: .2s ease;
        transition: .2s ease;
        height: 3.57143rem;
        line-height: 1.85714rem;
        border-radius: 0;
        &:hover {
          background-color: #9b7c4a;
        }
      }
    }
  }
}

.banner_box {
  float: right;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 27.14286rem;
  width: 25%;
  overflow: hidden;
  .banner {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-decoration: none;
    background: #002f6c;
    &:first-child {
      margin-bottom: 16px;
    }
    .btn {
      font-weight: bold;
    }
    .cta {
      display: block;
      background: #0A246A;
      color: white;
      font-weight: bold;
      padding: 8px 16px;
      text-transform: uppercase;
      line-height: 2;
      font-size: 18px;
      margin-bottom: 40px;
    }
  }
}

.overview__tabs,
.overview__panels {
  ul {
    padding: 0;
    margin: 0;
    vertical-align: top;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    li {
      float: left;
      position: relative;
      &:after {
        position: absolute;
        right: 0;
        top: 30%;
        content: '|';
      }
      &:last-of-type {
        &:after {
          content: none;
        }

      }
      a {
        display: block;
        padding: .85714em 2.14286em;
        text-decoration: none;
        text-transform: uppercase;
      }
    }
  }

}

.footer {
  .footer__copyright {
    position: relative;
  }
  .footer_author {
    display: block;
    text-decoration: none;
    position: absolute;
    left: 24px;
    top: calc(50% - 8px);
    width: 16px;
    height: 16px;
    &:before {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      opacity: 0.25;
      background: url('https://fedirko.pro/images/fedirko_gray.png') center no-repeat;
    }
    &:hover{
      &:before{
        opacity: 1;
      }
    }
  }
}

.wp-block-table {
  table {
    tbody {
      tr {
        td {

        }
      }
    }
  }
}

.why {
  .col.image {
    width: 30%;
    .elements__panel-image {
      width: auto;
    }
  }
  .col.content {
    width: 70%;
  }
}

.icon__instagram {
  -webkit-mask-image: url("../../images/icons/instagram-sketched.svg");
  mask-image: url("../../images/icons/instagram-sketched.svg");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  width: 1.07143rem;
  height: 1.07143rem;
  background-color: #666; }
.footer__social .icon__instagram {
  width: 1.71429rem;
  height: 1.71429rem; }

@media screen and (max-width: 1440px) { // max-width: 1440px
  .breadcrumbs a,
  .navigation__mobile a,
  .navigation__secondary a,
  .overview__tabs a,
  .search__loop a {
    word-break: break-all;
  }
}

@media screen and (max-width: 1024px) { // max-width: 1024px
  .widget_newsletterwidget {
    form {
      flex-direction: column;
      .tnp-privacy-field,
      .tnp-field-email,
      .tnp-field-button {
        margin-bottom: 16px;
        width: 100%;
        text-align: center;
        position: relative;
        top: 0;
        *,
        .tnp-email,
        .tnp-submit {
          max-width: 100%;
          width: 100%;
          border: none;
        }
      }
      .tnp-privacy-field {
        padding-left: 16px;
        label {
          &:before {
            top: -2px;
          }
          &:after {
            top: 7px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) { // max-width: 768px
  .banner_box {
    width: 40%;
  }
}

@media screen and (max-width: 590px) { // max-width: 590px
  .banner_box {
    width: 100%;
  }

  .overview__tabs,
  .overview__panels {
    ul {
      flex-direction: column;
      li {
        width: 100%;
        &:after {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 320px) { // max-width: 320px
}